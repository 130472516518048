import React from 'react'
import {
  GatsbyImage, 
  getImage,
} from "gatsby-plugin-image"

export default function FrontHero({ hero }) {
  
  const { field_hero_body, field_hero_title } = hero
  const body = field_hero_body?.value
  const heroImage = hero?.relationships?.field_hero_image
  const { field_image_caption } = heroImage
  const image = getImage(heroImage.relationships.field_media_image.localFile)
  
  return (
    <div className="relative overflow-hidden bg-sky-50 lg:pb-16 pt-24 lg:pt-24 lg:pb-0">
      <div className="mx-auto max-w-xl lg:max-w-7xl">
        <div className="relative z-10 bg-sky-50 pb-16 md:pt-1 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
          <svg
            className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-sky-50 lg:block"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl">
                <span className="block text-sky-700 xl:inline">{field_hero_title}</span>
              </h1>
              <div className="mt-3 text-base text-gray-700 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-8 lg:mx-0" 
                   dangerouslySetInnerHTML={{ __html: body }}>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <GatsbyImage className="h-64 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full" image={image} alt={field_image_caption} />
      </div>
    </div>
  )
}
