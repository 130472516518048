import * as React from "react"
import { Link } from "gatsby"

export default function Button({ title, url }) {
	return (
		<div className="mt-8">
      <Link to={url}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-200 px-5 py-3 text-base font-semibold text-sky-700 hover:bg-sky-300"
      >
        {title}
      </Link>
    </div>   
	)
}