import * as React from "react"
import { Link } from "gatsby"

export default function FrontMission({ blocks }) {

  const centredHeader = blocks[0]
  const button = blocks[1]
  const buttonLink = button.relationships.field_button_link.path.alias

  return (
    <div className="bg-white relative overflow-hidden py-16 sm:py-24 lg:py-24">
      <div className="mx-auto px-4 lg:text-center max-w-xl lg:max-w-7xl px-4 sm:px-6 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="text-xl text-gray-500">
          <h2 className="mx-2 text-3xl font-bold tracking-tight text-sky-900 sm:text-4xl">{centredHeader.field_centered_title}</h2>
          <div className="mx-auto mt-5 max-w-prose" 
               dangerouslySetInnerHTML={{ __html: centredHeader?.field_centered_subtitle?.processed }}>  
          </div>
          <div className="mt-8 flex lg:justify-center">
            <div className="inline-flex">
              <Link 
                to={buttonLink}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-200 px-5 py-3 text-base font-semibold text-sky-700 hover:bg-sky-300"
              >
                {button.field_button_title}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}