import * as React from "react"
import { Link } from "gatsby"
import NewsTeaser from "./news-teaser"

export default function LatestNews({ posts }) {
  return (
    <div className="bg-gray-50 pt-16 pb-20 lg:py-24">
      <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl mt-0 lg:text-center font-bold tracking-tight text-sky-900 sm:text-4xl">Latest news</h2>
        </div>
        <div className="mt-6 lg:pt-16 grid gap-16 pt-10 lg:grid-cols-3 lg:gap-x-10 lg:gap-y-12">
          {posts.map(post => 
            <NewsTeaser key={post.node.id} post={post} />
          )}
        </div>
      </div>
      <div className="mt-8 mx-4 sm:mx-auto max-w-xl flex sm:px-6 lg:justify-center">
        <div className="inline-flex">
            <Link to="/news"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-200 px-5 py-3 text-base font-semibold text-sky-700 hover:bg-sky-300">
              More DEC Housing news
            </Link>
        </div>
      </div>
    </div>
  )
}