import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import FrontHero from "../components/front-hero"
import SideBySide from "../components/front-side-by-side"
import FrontMission from "../components/front-mission"
import LatestNews from "../components/latest-news"

const IndexPage = ({ data }) => {
  
  const sections = data.sections.relationships.field_sections
  const hero = sections[0]

  const missionSection = sections[1]
  const missionBlocks = missionSection.relationships.field_section_blocks
  
  const sidebysideSections = sections[2]
  const sidebyside = sidebysideSections.relationships.field_section_blocks

  const posts = data.news.edges

  return (
    <Layout>
      <FrontHero hero={hero} />
      <FrontMission blocks={missionBlocks}  />
      <SideBySide sections={sidebyside} />
      <LatestNews posts={posts} />
    </Layout>
  )
}

export const Head = ({ location, data }) => {
  const canonicalUrl = `${process.env.GATSBY_SITE_URL}`
  const metaDescription = data.sections?.field_metatags?.description ?? ``
  return (
    <>
      <meta name="description" content={metaDescription} />
      <title>Dignity. Empowerment. Choice. | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    sections: nodePage(drupal_internal__nid: {eq: 10}) {
      field_metatags {
        description
      }
      relationships {
        field_sections {
          ...HeroFields
          ... on paragraph__section {
            relationships {
              field_section_blocks {
                ...CentredHeaderFields
                ...ButtonFields
                ...TwoColumnBlockFields
              }
            }
          }
        }
      }
    }
    news: allNodeNews(filter: {status: {eq: true}}, limit: 3, sort: {fields: created, order: DESC}) {
      edges {
        node {
          ...NewsTeaserFields
        }
      }
    }
  }
`